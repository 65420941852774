const sanitizeTitle = str => {
  const updatedString = str
    .toLowerCase()
    .trim()
    .replace(/[^A-Z0-9]+/gi, "-")
  return updatedString
}


module.exports = sanitizeTitle
